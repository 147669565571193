const VIDEO_ID = '3dnJ0M7SaFs';

var _player;
var _playerAdd = null;
var _globalURL = window.location.href;

(function () {

    var _setContent = false;
    var _quickViewIntro = false;
    var _scrollScreenFlag = false;
    var _bgSoundIsPlaying = false;
    var _backgroundSound;
    var _summaryData = {};
    var _sound;
    var _gGender;
    var _gLonely;
    var _cutSymbols = 0;
    var _waitingCheckerTimer;
    var _curNumWaitingWord = 0;
    var _questionBlock = false;
    var _menuCross = false;

    const isDesktop = window.outerWidth >= 992;

    const images = [];
    const audios = {};

    ElementsSizeOnDesktop();
    Preloader();
    Menu();

    Disclaimer($('#about-us'));
    Disclaimer($('#main-content'));
    Disclaimer($('#add-video'));

    function replaceSymbol(text) {
        var result = text
            .replace(new RegExp('o', 'g'), 'Ō')
            .replace(new RegExp('O', 'g'), 'Ō')
            .replace(new RegExp('о', 'g'), 'Ō')
            .replace(new RegExp('О', 'g'), 'Ō')
            .replace(new RegExp('о', 'g'), 'Ō')
            .replace(new RegExp('О', 'g'), 'Ō');

        return result;
    }

    function replaceQuoteattr(text) {
        var result = text
            .replace(/&/g, '&amp;')
            .replace(/'/g, '&apos;')
            .replace(/"/g, '&quot;')
            .replace(/</g, '&lt;')
            .replace(/>/g, '&gt;');

        return result;
    }

    function _validURL(str) {
        var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
        return !!pattern.test(str);
    }

    var AboutUsSection = function() {

        //private properties
        var _obj = $( '.about-us' ),
            _obj1 = _obj.find( '.about-us__wrap1' ),
            _obj3 = _obj.find( '.about-us__wrap3' ),
            _window = $( window ),
            _scrollContainer = $( '#about-us' ),
            _btnBack = _scrollContainer.find( '#js-about-back' ),
            _site = _scrollContainer.find( '.site--popup' ),
            _siteHeader = $( '.site__header' ),
            _carriage = $( '#for-about-us' ),
            _windowHalfHeight = _window.outerHeight() / 2;

        _carriage.hide();

        //private methods
        var _onEvent = function() {

                _window.on( 'resize', function () {
                    _windowHalfHeight = _window.outerHeight() / 2;
                } );

                _scrollContainer.on( 'scroll', function () {

                    var carriageOffset = ( _scrollContainer.scrollTop() * _carriage.outerHeight() ) / ( _site.outerHeight() - _window.outerHeight() );
                    var screenOffset = ( _scrollContainer.scrollTop() * _window.outerHeight() ) / ( _site.outerHeight() - _window.outerHeight() );

                    _carriage.css({
                        'top': screenOffset - carriageOffset +'px'
                    });

                    if ( _windowHalfHeight >= _obj1.offset().top ) {
                        _site.addClass( 'is-scroll' );
                    } else {
                        _site.removeClass( 'is-scroll' );
                    }

                    if ( _windowHalfHeight >= _obj1.offset().top && _windowHalfHeight <= _obj1.offset().top + _obj1.outerHeight() ) {
                        _site.addClass( 'is-black-theme' );
                        _siteHeader.addClass( 'is-dark' ).removeClass( 'is-light' );
                    } else {
                        _site.removeClass( 'is-black-theme' );
                        _siteHeader.removeClass( 'is-dark' ).addClass( 'is-light' );
                    }

                    if ( _windowHalfHeight >= _obj3.offset().top && _windowHalfHeight <= _obj3.offset().top + _obj3.outerHeight() ) {
                        _site.addClass( 'is-black-theme-1' );
                        _siteHeader.addClass( 'is-dark' ).removeClass( 'is-light' );
                    } else {
                        _site.removeClass( 'is-black-theme-1' );
                    }

                }  );

                _btnBack.on( 'click', function () {
                    $('#js-menu-back').trigger( 'click' );
                } );

            },
            _construct = function() {

                var carriageHeight = _site.outerHeight() / _window.outerHeight();

                _carriage.css({
                    height: ( carriageHeight < 20 ? 75 : carriageHeight ) +'px'
                });

                _onEvent();
            };

        //public properties

        //public methods

        _construct();
    };

    var AnimateStart = function ( callback ) {

        let frameIndex = 13;
        let tickCount = 0;
        let ticksPerFrame = 0;
        let startTime = 0;
        let numberOfFrames = 7;
        let mainCanvas = document.getElementById('stars-screen-canvas');
        let mainContext = mainCanvas.getContext('2d');
        let canvasWidth = mainCanvas.width;
        let canvasHeight = mainCanvas.height;

        function update() {
            tickCount++;

            if (tickCount > ticksPerFrame) {
                tickCount = 0;

                if (frameIndex > numberOfFrames + 1) {
                    frameIndex--;
                } else {
                    frameIndex--;
                    // TODO Star animation completed
                    callback();
                }
            }
        }

        function render() {
            if (frameIndex > numberOfFrames) {
                mainContext.clearRect(0, 0, canvasWidth, canvasHeight);
                mainContext.drawImage(images[frameIndex], 0, 0, canvasWidth, canvasHeight);
                mainContext.getImageData(0, 0, canvasWidth, canvasHeight);
            }
        }

        function start() {
            let loop = function(e) {
                const delta = e - startTime;

                if (delta > 100 && frameIndex > numberOfFrames) {
                    startTime = e;
                    update();
                    render();
                }

                if (frameIndex > numberOfFrames) {
                    window.requestAnimationFrame(loop);
                }
            };

            window.requestAnimationFrame(loop);
        }

        start();

    };

    var AnimateLogo = function (obj, callback) {

        var _obj = obj;

        var _buildIcons = function () {

                var params = {
                    container: _obj[0],
                    renderer: 'svg',
                    loop: false,
                    autoplay: true,
                    path: _obj.data('src')
                };

                var anim = lottie.loadAnimation(params);

                anim.addEventListener('complete', callback);

            },
            _construct = function () {
                _buildIcons();
            };

        _construct();
    };

    var AnimationParagraph = function (obj, callback) {

        //private properties
        var _title = obj,
            _titleTexts = JSON.parse(_title.attr('data-texts')),
            _titleTextsLength = _titleTexts.length - 1,
            _letterInterval = +_title.attr('data-letterInterval') || 50,
            _titleInterval = +_title.attr('data-text-interval') || 2000,
            _titleDuration = +_title.attr('data-duration') || 0,
            _startTime = -1,
            _titleCounter = 0,
            _symbolCounter = 0,
            _canShowSymbol = true,
            _timer = null,
            _timerDuration = null;

        //private methods
        var _constructor = function () {

                _title.removeClass('is-hide').addClass('is-animate');

                _timerDuration = setTimeout(function () {

                    _step(0);

                    clearTimeout(_titleDuration);
                }, _titleDuration);

            },
            _step = function (time) {

                var progress;

                if (_startTime < 0) {
                    _startTime = time;
                }

                progress = (time - _startTime) / _letterInterval;

                if (progress > 1) {
                    progress = 1;
                    _startTime = time;

                    if (_canShowSymbol) {
                        _showLetter();
                    }

                } else {
                    window.requestAnimationFrame(_step);
                }

            },
            _showLetter = function () {

                var tmp = _titleTexts[_titleCounter];

                if (_quickViewIntro === true) {

                    clearTimeout(_timer);

                    for (var i = 0; i < _titleTexts.length; i++) {
                        if (i === 0) {
                            _title.html(_titleTexts[i] + '</br>')
                        } else if (i === _titleTexts.length - 1) {
                            _title.append(_titleTexts[i])
                        } else {
                            _title.append(_titleTexts[i] + '</br>')
                        }
                    }

                    _title.addClass('is-thinking');

                    setTimeout(function () {
                        _quickViewIntro = false;

                        callback();
                    }, _titleInterval);

                    return;
                }

                _title.html(_title.html() + tmp.substr(_symbolCounter, 1));

                if (_symbolCounter < tmp.length) {
                    _symbolCounter++;
                    window.requestAnimationFrame(_step);
                } else {

                    _title.addClass('is-thinking');

                    _canShowSymbol = false;
                    _symbolCounter = 0;

                    if (_titleCounter < _titleTextsLength) {
                        _title.append('<br/>');
                        _titleCounter++;

                        _canShowSymbol = true;
                        _showLetter();

                        _title.removeClass('is-thinking');

                    } else {

                        _timer = setTimeout(function () {
                            _title.removeClass('is-thinking is-animate');

                            callback();
                        }, _titleInterval)

                    }

                }

            };

        _constructor();

    };

    var AnimationParagraph2 = function (obj, callback) {

        const iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
        const _wrap = $('.content');
        const _contentWrap = _wrap.find('.content__wrap');

        //private properties
        var _title = obj,
            _self = this,
            _titleTexts = replaceSymbol( _title.attr('data-texts') ),
            _letterInterval = +_title.attr('data-letterInterval') || 50,
            _titleInterval = +_title.attr('data-text-interval') || 100,
            _titleDuration = +_title.attr('data-duration') || 0,
            _startTime = -1,
            _symbolCounter = 0,
            _canShowSymbol = true,
            _timer = null,
            _timerDuration = null;

        //private methods
        var _constructor = function () {

                _title.removeClass('is-hide').addClass('is-animate');

                _timerDuration = setTimeout(function () {

                    _step(0);

                    clearTimeout(_titleDuration);
                }, _titleDuration);

                _title[0].obj = _self;

            },
            _step = function (time) {

                const iOSCondition = iOS && (_wrap[0].scrollTop === ( _wrap[0].scrollHeight - _wrap[0].offsetHeight ) );
                const otherPlatformsCondition = !iOS && ( ( _wrap[0].scrollTop + _wrap[0].offsetHeight + 5 ) >= _wrap[0].scrollHeight );
                const condition = iOSCondition || otherPlatformsCondition;

                if ( !condition ) {

                    window.requestAnimationFrame(_step);
                    _title.addClass('is-waiting').removeClass('is-animate');
                    return;

                }

                _title.removeClass('is-waiting').addClass('is-animate');

                var progress;

                if (_startTime < 0)
                    _startTime = time;

                progress = (time - _startTime) / _letterInterval;

                if (progress > 1) {
                    progress = 1;
                    _startTime = time;

                    if (_canShowSymbol) {
                        _showLetter();
                    }

                } else {
                    window.requestAnimationFrame(_step);
                }

            },
            _showLetter = function () {

                var tmp = _titleTexts;

                _title.text(_title.text() + tmp.substr(_symbolCounter, 1));

                _wrap.animate({ scrollTop: _contentWrap.outerHeight() }, 10 );

                if (_symbolCounter < tmp.length) {
                    _symbolCounter++;
                    window.requestAnimationFrame(_step);
                } else {

                    _title.addClass('is-thinking');

                    _timer = setTimeout(function () {
                        _canShowSymbol = false;
                        _symbolCounter = 0;

                        _title.removeClass('is-animate');

                        if (callback !== null)
                            callback();


                    }, _titleInterval);

                }

            };

        _self.continue = function () {
            _showLetter();
        };

        _constructor();

    };

    var ExplosionSection = function (obj) {

        const iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);

        var _wrap = obj,
            _self = this,
            _contentWrap = _wrap.find('.content__wrap'),
            _printParagraphsCounter = 1,
            _tokenCounter = 0,
            _allTokenCounter = 0,
            _prevData, _questionCounter = 1,
            _questionCounterAnalyze = 0,
            _preview = 3,
            _openQuestionBlock = false,
            _window = $(window),
            _scrollContainer = $('#main-content'),
            _site = _scrollContainer.find('.site'),
            _header = $('.site__header'),
            _carriage = $('#for-content'),
            _windowHalfHeight = _window.outerHeight() / 2;

        var _url = _validURL( window.GlURL ) ? window.GlURL : 'https://api.vryvobot.fun/';

        var _onEvent = function () {

                _wrap.on( 'scroll', function () {

                    var carriageOffset = ( _wrap.scrollTop() * _carriage.outerHeight() ) / ( _contentWrap.outerHeight() - _window.outerHeight() );
                    var screenOffset = ( _wrap.scrollTop() * _window.outerHeight() ) / ( _contentWrap.outerHeight() - _window.outerHeight() );

                    _carriage.css({
                        'top': screenOffset - carriageOffset +'px'
                    });

                }  );

            },
            _ajaxRequest = function (endpoint, data, callback) {

                _openQuestionBlock = true;

                WaitingChecker();

                _saveData( data );

                var request = {};

                if ( data !== null )
                    request = JSON.parse(data);

                request.gender = _gGender;
                request.lonely = _gLonely;

                $.ajax({
                    url: _url + endpoint,
                    dataType: 'json',
                    timeout: 20000,
                    type: "GET",
                    data: request,
                    success: function (data) {
                        WaitingCheckerClear();
                        callback(data);
                    },
                    error: function (XMLHttpRequest) {
                        if (XMLHttpRequest.statusText != 'abort') {
                            alert(XMLHttpRequest.statusText);
                        }
                    }
                });

            },
            _setParagraph = function (data) {

                if (data) {

                    _prevData = data.replies;

                    if ( _cutSymbols > 0 ){
                        _prevData = _prevData.slice( _cutSymbols );
                        _cutSymbols = 0;
                    }

                    var paragraph = $('<p class="js-paragraph" data-texts="' + replaceQuoteattr( _prevData ) + '"></p>');

                    _contentWrap.find('.is-waiting').removeClass('is-waiting');
                    _contentWrap.find('.is-thinking').removeClass('is-thinking');
                    _contentWrap.append(paragraph);

                    _printParagraph(paragraph);

                } else {

                    var out = {};
                    out['previous_context'] = _prevData +' ';

                    var storage = sessionStorage.getItem( 'revoWave' );

                    if ( storage ) {
                        sessionStorage.setItem('revoWave', storage + out.previous_context + ' ');
                    } else {
                        sessionStorage.setItem('revoWave', out.previous_context + ' ');
                    }

                    if (_tokenCounter === _preview ) {
                        _tokenCounter = 0;

                        _setRandomQuestion();

                    } else {

                        if (_printParagraphsCounter === _preview) {
                            _printParagraphsCounter = 0;

                            _ajaxRequest('generateContinuations', JSON.stringify(out), _addToken);

                        } else {
                            _printParagraphsCounter++;

                            _ajaxRequest('generateSample', JSON.stringify(out), _setParagraph);
                        }

                    }

                }

            },
            _printParagraph = function (paragraph) {

                if (_setContent) {
                    _openQuestionBlock = false;
                    new AnimationParagraph2(paragraph, _setParagraph);
                }

            },
            _addToken = function (data) {

                _prevData = data.replies;

                if ( _allTokenCounter > 0 )
                    SharingButtons();

                var paragraph = $('<p class="js-paragraph" data-texts="' + replaceQuoteattr( _prevData.sample_start ) + '"></p>');

                if ( _allTokenCounter === 0 ) {
                    _contentWrap.css( 'padding-top', '90px' )
                    paragraph.addClass('content__paragraph-delimiter');
                    _wrap.animate({ scrollTop: _contentWrap.outerHeight() }, 300 );
                }

                _contentWrap.append( paragraph );

                _contentWrap.find('.is-waiting').removeClass('is-waiting');
                _contentWrap.find('.is-thinking').removeClass('is-thinking');

                _openQuestionBlock = false;
                new AnimationParagraph2(paragraph, _openTokenWrap);

                var container = $("<div class=\"content__checker\"></div>");
                var containerWrapper = $('<ul class="content__checker-wrap"></ul>');

                var items = _prevData.sample_continuations;

                for (var key in items) {

                    let item = items[key].first_word;

                    if ( !item || item === '' )
                        continue;

                    containerWrapper.append('<li data-texts="'+ item +'"><span>' + replaceSymbol(item) + '</span></li>')
                }

                container.append( containerWrapper );

                // new SharingButtons( container );

                _contentWrap.append(container);

                _allTokenCounter++;

                gtag( 'event', 'watched_stories', { 'event_category': 'dialog', 'event_label': _allTokenCounter } );
                console.log('watched_stories / dialog /  ' + _allTokenCounter )

            },
            _openTokenWrap = function () {

                _openQuestionBlock = true;

                var container = $('.content__checker:last-child'),
                    containerHeight = container.find('.content__checker-wrap').outerHeight();

                container.css('height', containerHeight + "px");

                container.on( 'webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend', function() {
                    container.css( 'overflow', 'visible' );
                } );

                _wrap.animate({ scrollTop: _contentWrap.outerHeight() + containerHeight }, 300 );

                _contentWrap.find('.is-thinking').addClass('is-waiting').removeClass('is-thinking');

                _setContent = true;

                $('.content__checker li').on('click', function () {

                    _tokenCounter++;

                    _contentWrap.css('min-height', _contentWrap.outerHeight() + 'px');

                    container.hide();

                    _openQuestionBlock = false;

                    gtag( 'event', 'token_click', { 'event_category': 'dialog', 'event_label': ( $(this).index() + 1 ) } );
                    console.log('token_click / dialog / ' + ( $(this).index() + 1 ) )

                    sessionStorage.setItem( 'revoWave', container.prev('p').data('texts') +' ' );

                    var out = {};
                    out['start_context'] = _contentWrap.find('.js-paragraph:last-of-type').data('texts') +' ';

                    _cutSymbols = out['start_context'].length;

                    out['start_context'] = out['start_context']+ $(this).data('texts') +' ';

                    _contentWrap.find('.is-waiting').removeClass('is-waiting').addClass('is-thinking')

                    _ajaxRequest('generateSample', JSON.stringify(out), _setParagraph);

                    _wrap.animate({ scrollTop: _contentWrap.outerHeight() + containerHeight }, 300 );

                } );

            },
            _saveData = function ( data ) {

                var data = JSON.parse( data );

                if ( !data ) {
                    localStorage.removeItem( 'revoVryv' );
                    return;
                }

                var storage = localStorage.getItem( 'revoVryv' );

                if ( !storage ) {
                    localStorage.setItem( 'revoVryv', data.previous_context )
                } else {
                    localStorage.setItem( 'revoVryv', storage + data.previous_context )
                }

            },
            _setRandomQuestion = function () {

                var questionWrap = $( '<div class="content__question is-hide"></div>' );
                var questionText, answer1, answer2;

                switch (_questionCounter) {
                    case 0:
                        questionText = _summaryData.pop_up_question1;
                        answer1 = _summaryData.pop_up_question1_answer1;
                        answer2 = _summaryData.pop_up_question1_answer2;
                        break;
                    case 1:
                        questionText = _summaryData.pop_upquestion2;
                        answer1 = _summaryData.pop_up_question2_answer1;
                        answer2 = _summaryData.pop_up_question2_answer2;
                        break;
                    case 2:
                        questionText = _summaryData.pop_up_question3;
                        answer1 = _summaryData.pop_up_question3_answer1;
                        answer2 = _summaryData.pop_up_question3_answer2;
                        break;
                    case 3:
                        questionText = _summaryData.pop_up_question4;
                        answer1 = _summaryData.pop_up_question4_answer1;
                        answer2 = _summaryData.pop_up_question4_answer2;
                        break;
                    case 4:
                        questionText = _summaryData.pop_up_question5;
                        answer1 = _summaryData.pop_up_question5_answer1;
                        answer2 = _summaryData.pop_up_question5_answer2;
                        break;
                    case 5:
                        questionText = _summaryData.pop_up_question6;
                        answer1 = _summaryData.pop_up_question6_answer1;
                        answer2 = _summaryData.pop_up_question6_answer2;
                        break;
                    case 6:
                        questionText = _summaryData.pop_up_question7;
                        answer1 = _summaryData.pop_up_question7_answer1;
                        answer2 = _summaryData.pop_up_question7_answer2;
                        break;
                    case 7:
                        questionText = _summaryData.pop_up_question8;
                        answer1 = _summaryData.pop_up_question8_answer1;
                        answer2 = _summaryData.pop_up_question8_answer2;
                        break;
                    case 8:
                        questionText = _summaryData.pop_up_question9;
                        answer1 = _summaryData.pop_up_question9_answer1;
                        answer2 = _summaryData.pop_up_question9_answer2;
                        break;
                    case 9:
                        questionText = _summaryData.pop_up_question10;
                        answer1 = _summaryData.pop_up_question10_answer1;
                        answer2 = _summaryData.pop_up_question10_answer2;
                        break;
                    case 10:
                        questionText = _summaryData.pop_up_question11;
                        answer1 = _summaryData.pop_up_question11_answer1;
                        answer2 = _summaryData.pop_up_question11_answer2;
                        _questionCounter = 0;
                        break;
                }

                questionWrap.append( '<div class="content__question-layout"><div class="content__question-wrap"><div>' +
                    '<p>'+ questionText +'</p><ul class="content__question-btn">' +
                    '<li><span class="js-content-check"><span>'+ answer1 +'</span></span></li>' +
                    '<li><span class="js-content-check"><span>'+ answer2 +'</span></span></li>' +
                    '</ul></div></div></div>');

                _questionCounter++;
                _questionCounterAnalyze++;

                gtag( 'event', 'questions_show', { 'event_category': 'dialog', 'event_label': _questionCounterAnalyze } );
                console.log('questions_show / dialog / ' + _questionCounterAnalyze)

                _contentWrap.append( questionWrap );

                _openQuestion();

            },
            _openQuestion = function () {

                _openQuestionBlock = true;

                // _setCarriageHeight();

                var container = $('.content__question:last-child'),
                    containerHeight = container.find('.content__question-wrap').outerHeight(),
                    btns = container.find('.js-content-check');

                container.removeClass('is-hide');
                container.css('height', containerHeight + 'px');

                _wrap.animate({ scrollTop: _contentWrap.outerHeight() + containerHeight }, 300 );

                _contentWrap.find('.is-thinking').addClass('is-waiting').removeClass('is-thinking');

                _setContent = true;

                btns.on('click', function () {

                    sessionStorage.removeItem( 'revoWave' );

                    var out = {};
                    out['previous_context'] = _contentWrap.find('.content__question').prev('.js-paragraph').data( 'texts' ) +'\n';

                    // container.find( '.control' ).remove();

                    $(this).addClass('is-active');

                    gtag( 'event', 'questions_click', { 'event_category': 'dialog', 'event_label': $(this).text() } );
                    console.log('questions_click / dialog / ' + $(this).text())

                    setTimeout( function () {

                        _contentWrap.css('min-height', _contentWrap.outerHeight() + 'px');
                        container.css( 'height', 0 );

                        container.on( 'webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend', function(e) {
                            container.off( 'webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend');
                            container.hide();
                            _contentWrap.append('<span class="js-paragraph is-thinking"></span>');
                            _ajaxRequest('generateSample', JSON.stringify(out), _setParagraph);
                            _contentWrap.find('.is-waiting').removeClass('is-waiting');
                        } );

                    }, 1000 );

                    btns.off('click');

                    _openQuestionBlock = false;

                });

            },
            _setFirstScreen = function () {

                if ( iOS ){
                    _wrap.css( 'display', 'block' );
                    _contentWrap.css( 'padding-top', '200px' )
                }

                sessionStorage.removeItem( 'revoWave' );

                $('.site__content').remove();
                _site.addClass('is-black-theme is-add-slogan is-blur-back');
                $('.stars-screen').addClass('is-hide');
                $('.scroll-container').css({
                    'point-event': 'none'
                });
                _header.addClass('is-dark');
                $('.promo').remove();
                _wrap.removeClass('is-hide');

                gtag('config','UA-157469096-1', {'page_location': '/dialog'});
                console.log('/dialog');

                _onEvent();
                _printParagraph(_contentWrap.find('.js-paragraph:last-of-type'));

                var carriageHeight = _wrap.outerHeight() / _window.outerHeight();

                _carriage.css({
                    top: _wrap.outerHeight(),
                    height: ( carriageHeight < 20 ? 75 : carriageHeight ) +'px'
                });

            },
            _constructor = function () {

                _ajaxRequest('generateSample', null , _setParagraph);
                _wrap[0].obj = _self;

            };

        //public methods
        _self.changeView = function () {
            _setContent = true;

            _setFirstScreen();
        };

        _constructor();

    };

    var PageBuilder = function () {

        var _onEvents = function () {

                $(window).on({
                    'resize': function () {

                        $('.site__content').css('height', $(window).innerHeight());
                        $('.site__content > *').css('height', $(window).innerHeight());

                        if ( $(window).outerWidth() > 1024 ){
                            $('.site__content').css('height', $(window).innerHeight() + $('.site__content').find('.disclaimer').innerHeight() );
                            $('.site__content').find('.disclaimer').css('display', 'block');
                        }

                    }
                });

                // $('.navigation li[data-rote="first-contact"]').on( 'click', function () {
                //     _modelQuestionsScreen();
                //     _menuCross = true;
                // });

                $('.logo').on('click', function () {
                    _modelDisclaimScreen();

                    $('#js-menu-back').trigger( 'click' );

                    $('#js-menu-about').remove();
                    $('.hamburger').removeClass('is-hide');
                });

            }
            _setParagraph = function (data, mark, textInterval, duration) {

                var result = data.split('\n');

                if (result[result.length - 1] == '')
                    result.splice(-1, 1);

                for (var i = 0; i < result.length - 1; i++) {
                    result[i] = result[i] + ' '
                }

                var out = $('<p class="js-paragraph is-hide" data-texts=\'' + replaceSymbol(JSON.stringify(result)) + '\'></p>');

                if (textInterval)
                    out.attr('data-text-interval', textInterval);

                if (duration)
                    out.attr('data-duration', duration);

                if (mark)
                    out.addClass(mark);

                return out;
            },
            _modelIntroScreen = function () {

                console.log('_modelIntroScreen');

                gtag( 'event', 'loaded', { 'event_category': 'preparation' } );
                console.log('loaded / preparation')

                var curScreen = $('.intro'),
                    contentWrap = curScreen.find('.intro__content'),
                    skipBtn = curScreen.find('.intro__skip'),
                    _skipIntroFlag = true;

                curScreen.addClass('is-show');

                var paragraphFlag = 0;

                curScreen.on('click', function () {

                    if (!_quickViewIntro)
                        _quickViewIntro = true;

                });

                skipBtn.on('click', function () {
                    _modelVideoScreen();

                    gtag( 'event', 'btn_skip_click', { 'event_category': 'preparation' } );
                    console.log('btn_skip_click / preparation')

                    _skipIntroFlag = false;
                });

                var typingParagraph = function () {

                    if (!_skipIntroFlag)
                        return;

                    switch (paragraphFlag) {
                        case 0:
                            contentWrap.html(_setParagraph(_summaryData.headline_1, null, 1000));
                            AnimationParagraph(contentWrap.find('.js-paragraph.is-hide'), typingParagraph);
                            break;
                        case 1:
                            contentWrap.append(_setParagraph(_summaryData.headline_2, null, 1000));
                            AnimationParagraph(contentWrap.find('.js-paragraph.is-hide'), typingParagraph);

                            break;
                        default:
                            _modelVideoScreen();

                            gtag( 'event', 'intro_finished', { 'event_category': 'preparation' } );
                            console.log('intro_finished / preparation')

                            return;
                    }

                    paragraphFlag++;

                };

                typingParagraph();

            },
            _modelVideoScreen = function () {

                console.log('_modelVideoScreen');

                $('.site').addClass('is-black-theme is-add-slogan');
                $('.site__header').removeClass('is-hide').addClass('is-on-video');
                $('.stars-screen').addClass('is-hide');

                new PlayBackgroundSound ();
                ShowAboutUs();
                AboutUsSection();

                var curScreen = $('.site__content .video'),
                    prevScreen = curScreen.prev(),
                    playAudioBtn = $('.play-audio'),
                    playBtn = curScreen.find('.video__play-btn'),
                    skipBtn = curScreen.find('.video__skip'),
                    videoCover = curScreen.find( '.video__cover' )[0],
                    _canvas = document.getElementById('video__cover-canvas'),
                    _ctx = _canvas.getContext('2d'),
                    aboutSwitch = $('#js-menu-about');

                prevScreen.remove();
                curScreen.addClass('is-show');

                if ( $(window).outerWidth() < 768 ){
                    window.requestAnimationFrame(loop);

                    _canvas.width = $(window).outerWidth();
                    _canvas.height = $(window).outerHeight();
                    _ctx.clearRect(0, 0, _canvas.width, _canvas.height);

                }

                /*videoObj.addEventListener( 'ended', function () {
                    _modelLogoScreen();

                    if ( _bgSoundIsPlaying ){
                        _backgroundSound.play();
                    }

                } );

                videoObj.addEventListener( 'playing', function () {
                    videoObj.setAttribute("controls","controls");

                    if ( _bgSoundIsPlaying ){
                        _backgroundSound.pause();
                    }

                } );

                videoObj.addEventListener( 'pause', function () {

                    if ( _bgSoundIsPlaying ){
                        _backgroundSound.play();
                    }

                } );*/

                aboutSwitch.on( 'click', function () {

                    _player.pauseVideo();

                    if ( _bgSoundIsPlaying ){
                        _backgroundSound.play();
                    }

                } )

                playAudioBtn.on( 'click', function () {

                    if ( _player && _player.getPlayerState() == 1 && _bgSoundIsPlaying ){
                        _backgroundSound.pause();
                    }

                } )

                playBtn.on( 'click', function (e) {
                    e.preventDefault();

                    playBtn.addClass('is-active');

                    setTimeout( function () {

                        videoCover.remove();
                        _canvas.remove();

                        _player.playVideo();

                        var _playersWrap = curScreen.find('iframe')[0];

                        if ( $(window).outerWidth() < 767 ){

                            var requestFullScreen = _playersWrap.requestFullScreen || _playersWrap.mozRequestFullScreen || _playersWrap.webkitRequestFullScreen;
                            if (requestFullScreen) {
                                requestFullScreen.bind(_playersWrap)();
                            }

                        }

                        curScreen.addClass('is-playing');

                        gtag( 'event', 'video_play', { 'event_category': 'phase2' } );
                        console.log('video_play / phase2')

                    }, 500 )

                } );

                skipBtn.on('click', function () {
                    _modelLogoScreen();
                    _player.stopVideo();

                    gtag( 'event', 'video_skip', { 'event_category': 'phase2' } );
                    console.log('video_skip / phase2')

                });

                _player.addEventListener( 'onStateChange', function (state) {
                    switch (state.data) {
                        case 0: // ended
                            _modelLogoScreen();

                            gtag( 'event', 'video_finish', { 'event_category': 'phase2' } );
                            console.log('video_finish / phase2')

                            if ( _bgSoundIsPlaying ){
                                _backgroundSound.play();
                            }
                            break;
                        case 1: // playing

                            if ( _bgSoundIsPlaying ){
                                _backgroundSound.pause();
                            }

                            break;
                        case 2: // pause

                            if ( _bgSoundIsPlaying ){
                                _backgroundSound.play();
                            }
                            break;
                    }
                } );

                $(window).on('resize', function () {
                    _canvas.width = $(window).outerWidth();
                    _canvas.height = $(window).outerHeight();
                })

                function loop(){

                    var canvasH = $(window).outerWidth() / 375 * $(window).outerHeight();
                    var canvasW = $(window).outerWidth();
                    var canvasSY = ( canvasH - $(window).outerHeight() ) / 2 * -1;
                    var canvasSX = 0;

                    if ( canvasH < $(window).outerHeight() ){
                        canvasH = $(window).outerHeight();
                        canvasSY = 0;
                        canvasW = $(window).outerHeight() / 666 * $(window).outerWidth();
                        canvasSX = ( canvasW - $(window).outerWidth() ) / 2 * -1;
                    }

                    _ctx.drawImage(videoCover, canvasSX, canvasSY, canvasW, canvasH);

                    if ($('#video__cover-canvas').length == 0)
                        return;

                    window.requestAnimationFrame(loop);
                }

            },
            _modelLogoScreen = function () {

                console.log('_modelLogoScreen');

                $('.site__header').addClass('is-hide');
                $('#video__cover-canvas').remove();

                var curScreen = $('.hero'),
                    prevScreen = curScreen.prev(),
                    logo = curScreen.find('.js-animate-svg');

                prevScreen.remove();
                curScreen.addClass('is-show');

                var animateLogo = function () {
                    curScreen.find('.hero__wrap').addClass( 'is-show' );
                    new AnimateLogo( logo, _modelDisclaimScreen);
                };

                if( $(window).outerWidth() < 992 ){
                    $('.stars-screen').removeClass('is-hide').addClass('is-playing');
                    AnimateStart( animateLogo ) ;
                } else {
                    animateLogo();
                }

            },
            _modelDisclaimScreen = function () {

                console.log('_modelDisclaimScreen');

                $('.site__header').removeClass('is-hide').addClass('id-dark');

                $('#js-menu-about').remove();
                $('.hamburger').removeClass('is-hide');

                if( $(window).outerWidth() < 992 ){
                    $('.stars-screen').addClass('is-opacity');
                }

                var curScreen = $('.notice'),
                    prevAllScreens = curScreen.prevAll(),
                    contentWrap = curScreen.find('.notice__content');

                prevAllScreens.remove();
                curScreen.addClass('is-show');

                $('.logo').off().on('click', function () {
                    $('.navigation li[data-rote="first-contact"]').trigger( 'click' );
                    $('#js-menu-back').trigger( 'click' );
                });

                contentWrap.html(_setParagraph(_summaryData.main_headline ));
                AnimationParagraph( contentWrap.find('.js-paragraph.is-hide'), _modelQuestionsScreen);

            },
            _modelQuestionsScreen = function () {

                console.log('_modelQuestionsScreen');

                var curScreen = $('.first-contact'),
                    prevScreen = curScreen.prev(),
                    prevAllScreens = curScreen.prevAll();

                $('.stars-screen').addClass('is-hide');
                $('.site__header').removeClass('is-dark is-on-video');

                prevScreen.remove();
                prevAllScreens.remove();
                curScreen.addClass('is-show');

                $('.site').addClass('is-add-slogan').removeClass('is-black-theme');

                if ( !_questionBlock ){
                    new QuestionsSections(curScreen, _modelLoadScreen);
                }

            },
            _modelLoadScreen = function () {

                console.log('_modelLoadScreen');

                $('.navigation li[data-rote="first-contact"], .logo').off().on('click', function () {
                    $('.hamburger').removeClass( 'is-active' );
                    $('.navigation').removeClass( 'is-show' );

                    if ( _playerAdd !== null ){
                        _playerAdd.destroy();
                        _playerAdd = null;
                    }

                    $( '.hamburger' ).removeClass('is-hide');

                    $('#add-video').removeClass( 'is-active' );
                    $('#about-us').removeClass( 'is-active' );
                    $('.content').css('pointer-events','all');

                    $('#for-content').show();
                    $('#for-about-us').hide();

                    $('.site').removeClass('is-dark-theme');
                    $('.site__header').removeClass( 'is-dark' );

                    if ( !$('.content').hasClass( 'is-hide' ) ) {
                        $('.site__header').addClass( 'is-dark' );
                    }

                    $('#js-menu-back').trigger( 'click' );

                });

                $('.site').removeClass('is-add-slogan');

                new ExplosionSection($('.content'));

                var curScreen = $('.promo'),
                    prevScreen = curScreen.prev();

                prevScreen.remove();
                curScreen.addClass('is-show');

                let foldsContent = Array.from( document.getElementsByClassName('fold-content') );
                let start;

                if ( _bgSoundIsPlaying && _sound ){

                    _backgroundSound.pause();

                    _sound.play();
                    _sound.loop = true;

                }

                let tick = (e) => {

                    if (!start) {
                        start = e;
                    }

                    if ((e - start) > 2600) {
                        start = e;
                    }

                    foldsContent.forEach(content => {
                        content.style.transform = `translateY(${(start - e) * .4}px)`;
                    });

                    window.requestAnimationFrame(tick);

                };

                tick();

                setTimeout(function () {

                    if ( _bgSoundIsPlaying ) {
                        _sound.pause();
                        _backgroundSound.play();
                    }

                    $('.content')[0].obj.changeView();

                }, 6000)

            },
            _constructor = function () {
                _onEvents();
                _modelIntroScreen();

                $('.site__content').css('height', $(window).innerHeight());
                $('.site__content > *').css('height', $(window).innerHeight());

                if ( $(window).outerWidth() > 1024 ){
                    $('.site__content').css('height', $(window).innerHeight() + $('.site__content').find('.disclaimer').innerHeight() );
                    $('.site__content').find('.disclaimer').css('display', 'block');
                }

                if ( $(window).outerWidth() > 768 ){

                    var videoCover = document.querySelectorAll( '.video__cover' );
                    console.log(videoCover)

                    videoCover.forEach( function (item) {
                        item.src = 'video/revo_cover_desk.mp4';
                        item.removeAttribute( 'controls' );
                    } )

                }

            };

        _constructor();

    };

    var PlayBackgroundSound = function () {

        var btn = $( '.play-audio' );
        var volumeFade;

        var params = {
            container: btn[0],
            renderer: 'svg',
            loop: true,
            autoplay: true,
            path: btn.data('img')
        };

        var anim = lottie.loadAnimation(params);

        _backgroundSound = audios['bg'];
        _sound = audios['loading'];

        if ( !_backgroundSound )
            return;

        _backgroundSound.loop = true;

        btn.on( 'click', function () {

            btn.removeClass( 'is-off' );

            if ( btn.hasClass( 'is-playing' ) ){

                btn.removeClass( 'is-playing' );
                _backgroundSound.pause();
                _bgSoundIsPlaying = false;

                if ( _sound.play() ) {
                    _sound.pause();
                }

                anim.goToAndStop(0, true)

                gtag( 'event', 'volume_click', { 'event_category': 'common', 'event_label': 'Off' } );
                console.log('volume_click / common / Off')

            } else {

                btn.addClass( 'is-playing' );
                _backgroundSound.play();
                _bgSoundIsPlaying = true;

                anim.play()

                gtag( 'event', 'volume_click', { 'event_category': 'common', 'event_label': 'On' } );
                console.log('volume_click / common / On')

            };

        } );

    };

    var QuestionsSections = function (obj, callback) {

        var _screenTopic = obj.find('.first-contact__topic'),
            _topicItem = _screenTopic.find('li'),
            _pagination = obj.find('.first-contact__pagination'),
            _paginationItem = _pagination.find('li'),
            _paginationItemSize = _paginationItem.filter('.is-passed'),
            _mainWarp = obj.find('.first-contact__body'),
            _screens = _mainWarp.find('.first-contact__wrap'),
            _firstScreen = _mainWarp.find('.slide--age'),
            _buttonAgeControl = _firstScreen.find('.js-age-control'),
            _buttonAgeLess18 = _firstScreen.find('.js-age-link'),
            _secondScreen = _mainWarp.find('.slide--sex'),
            _buttonGenderCheck = _secondScreen.find('.js-gender-check'),
            _thirdScreen = _mainWarp.find('.slide--company'),
            _buttonCompanyCheck = _thirdScreen.find('.js-company-check'),
            _screenSize = $(window).outerWidth();

        var _onEvent = function () {

                $(window).on('resize', _setDimension);

                _paginationItem.on('click', function () {

                    var index = $(this).index();

                    _swipeQuestionWrap(index);

                    gtag( 'event', 'question_nubmer_click', { 'event_category': 'preparation', 'event_label': index++ } );
                    console.log('question_nubmer_click / preparation / '+ ( index++ ) )

                });

                _buttonAgeControl.on('click', function () {
                    _buttonAgeControl.removeClass('is-active');
                    $(this).addClass('is-active');

                    gtag( 'event', 'question_1_click', { 'event_category': 'preparation', 'event_label': 'Yes' } );
                    console.log('question_1_click / preparation / Yes')

                    _playAudio( $(this), _swipeQuestionWrap, 1 )

                });

                _buttonAgeLess18.on('click', function (e) {
                    e.preventDefault();
                    _buttonAgeControl.removeClass('is-active');
                    $(this).addClass('is-active');

                    gtag( 'event', 'question_1_click', { 'event_category': 'preparation', 'event_label': 'No' } );
                    console.log('question_1_click / preparation / No')

                    _playAudio( $(this), _hrefToPage, $(this) )

                });

                _buttonGenderCheck.on('click', function () {
                    _buttonGenderCheck.removeClass('is-active');
                    $(this).addClass('is-active');

                    $(this).data('label') === "Male" ?  _gGender = 'm' : _gGender = 'f';

                    gtag( 'event', 'question_2_click', { 'event_category': 'preparation', 'event_label': $(this).data('label') } );
                    console.log('question_2_click / preparation / ' + $(this).data('label'))

                    _playAudio( $(this), _swipeQuestionWrap, 2 )

                });

                _buttonCompanyCheck.on('click', function () {
                    $(this).addClass('is-active');

                    $(this).data('label') === "loner" ?  _gLonely = true : _gLonely = false;

                    gtag( 'event', 'question_3_click', { 'event_category': 'preparation', 'event_label': $(this).data('label') } );
                    console.log('question_3_click / preparation / ' + $(this).data('label'))

                    _playAudio( $(this), callback )
                });

            },
            _playAudio = function( obj, callback, index ) {
                const audio = audios[obj.data('audio')];

                if ( !audio || !_bgSoundIsPlaying ) {
                    callback(index);
                    return;
                }

                audio.play();

                setTimeout( function () {
                    callback(index);
                }, 500 )

            },
            _hrefToPage = function(obj) {

                window.location.href = obj.attr( 'href' );

            },
            _swipeQuestionWrap = function (index) {

                // _mainWarp.css('transform', 'translateX(' + _screenSize * index * -1 + 'px)');
                _screens.removeClass('is-active').eq(index).addClass('is-active');

                _paginationItem.removeClass('is-current').eq(index).addClass('is-passed is-current');

                _topicItem.removeClass('is-active').eq(index).addClass('is-active');

                switch (index) {
                    case 0:
                        gtag('config','UA-157469096-1', {'page_location': '/question_1'});
                        console.log('/question_1');
                        break;
                    case 1:
                        gtag('config','UA-157469096-1', {'page_location': '/question_2'});
                        console.log('/question_2');
                        break;
                    case 2:
                        gtag('config','UA-157469096-1', {'page_location': '/question_3'});
                        console.log('/question_3');
                        break;
                }

            },
            _setDimension = function () {
                _screenSize = $(window).outerWidth();

                // _mainWarp.css('transform', 'translateX(' + _screenSize * _screens.filter('.is-active').index() * -1 + 'px)');

                _screens.css('width', _screenSize);
                _paginationItemSize = _paginationItem.filter('is-active');

            },
            _constructor = function () {
                _onEvent();
                _questionBlock = true;
                gtag('config','UA-157469096-1', {'page_location': '/question_1'});
                console.log('/question_1');
            };

        _constructor();

    };

    var SetPageContent = function () {

        var _request;

        var _ajaxRequest = function () {

                _request = $.ajax({
                    type: 'GET',
                    url: 'content.csv',
                    dataType: 'text',
                    timeout: 20000,
                    success: function (data) {
                        Papa.parse(data, {
                            complete: function (results) {
                                _setContentData(results.data);
                            }
                        });
                    },
                    error: function (err) {
                        console.error(err);
                    }
                });

            },
            _setContentData = function (data) {

                data.forEach(function (item) {
                    _summaryData[item[0]] = item[1];
                });

                $( '#c-age_choice_description1' ).text( _summaryData.age_choice_description1 );
                $( '#c-sex_choice_description' ).text( _summaryData.sex_choice_description );
                $( '#c-number_choice_description' ).text( _summaryData.number_choice_description );

                new PageBuilder();

            },
            _constructor = function () {
                _ajaxRequest();
            };

        _constructor();

    };

    var SharingButtons = function ( obj ) {

        const _wrap = $('.content' );
        const _contentWrap = $('.content__wrap');

        var _btnObj = $( `<div class="control" data-text='${ replaceQuoteattr( sessionStorage.getItem('revoWave') ) }'><div>
                    <div id="js-btn-social" class="control__btn control__main-btn">
                        <svg viewBox="0 0 16 16" style="enable-background:new 0 0 16 16;" xml:space="preserve">
                            <g transform="translate(0,-1036.3622)">
                                <path class="control__circle" d="M15,1039.4c0,1.1-0.9,2-2,2s-2-0.9-2-2c0-1.1,0.9-2,2-2S15,1038.3,15,1039.4z"/>
                                <path class="control__circle" d="M15,1049.4c0,1.1-0.9,2-2,2s-2-0.9-2-2c0-1.1,0.9-2,2-2S15,1048.3,15,1049.4z"/>
                                <path class="control__circle" d="M5,1044.4c0,1.1-0.9,2-2,2s-2-0.9-2-2c0-1.1,0.9-2,2-2S5,1043.3,5,1044.4z"/>
                                <path class="control__elem" d="M13,1039.4l-10,5l10,5"/>
                            </g>
                        </svg>
                    </div>
                    <div class="control__list">
                        <a href="#" id="js-btn-facebook" class="control__btn" target="_blank">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 22.4 48.3" style="enable-background:new 0 0 22.4 48.3;" xml:space="preserve">
                                <path d="M22.4,15.6h-7.6v-5c0-1.9,1.2-2.3,2.1-2.3c0.9,0,5.4,0,5.4,0V0l-7.4,0C6.6,0,4.8,6.2,4.8,10.1v5.5H0v8.5h4.8 c0,10.9,0,24.1,0,24.1h10c0,0,0-13.3,0-24.1h6.8L22.4,15.6z"/>
                            </svg>
                        </a>
                        <a href="#" id="js-btn-telegram" class="control__btn" target="_blank">
                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 460 385.8" style="enable-background:new 0 0 460 385.8;" xml:space="preserve">
                                <path d="M458.7,35.1l-69.4,327.4c-5.2,23.1-18.9,28.9-38.3,18l-105.8-77.9l-51,49.1c-5.6,5.6-10.4,10.4-21.3,10.4l7.6-107.7 l196-177.1c8.5-7.6-1.8-11.8-13.2-4.2L120.9,225.5L16.6,192.8c-22.7-7.1-23.1-22.7,4.7-33.6L429.4,2.1 C448.3-5,464.8,6.3,458.7,35.1z"/>
                            </svg>
                        </a>
                        <span id="js-btn-link" class="control__btn">
                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 40 20" style="enable-background:new 0 0 40 20;" xml:space="preserve">
                                <path d="M-4-14h48v48H-4V-14z" style="display:none;fill:none;"/>
                                <path d="M3.8,10c0-3.4,2.8-6.2,6.2-6.2h8V0h-8C4.5,0,0,4.5,0,10s4.5,10,10,10h8v-3.8h-8C6.6,16.2,3.8,13.4,3.8,10z M12,12h16V8H12 V12z M30,0h-8v3.8h8c3.4,0,6.2,2.8,6.2,6.2s-2.8,6.2-6.2,6.2h-8V20h8c5.5,0,10-4.5,10-10S35.5,0,30,0z"/>
                            </svg>
                        </span>
                    </div>
                </div></div>` );

        var _sharingData = {
            title: "Згенеруй кайфовий двіж від штучного інтелекту за будь-яких умов",
            text: "REVO kAIf – перший у світі штучний інтелект, навчений вриватися"
        };

        var _data,
            _socialList = _btnObj.find('.control__list'),
            _btnSocial = _btnObj.find( '#js-btn-social' ),
            _btnShareLink = _btnObj.find( '#js-btn-link' ),
            _btnShareFacebook = _btnObj.find( '#js-btn-facebook' ),
            _btnShareTelegram = _btnObj.find( '#js-btn-telegram' );

        var _onEvents = function () {

                _btnSocial.on( 'click', function () {

                    if ( _btnObj.hasClass( 'is-show' ) ) {
                        _btnObj.removeClass( 'is-show' )
                    } else {

                        gtag( 'event', 'share_click', { 'event_category': 'dialog', 'event_label': 'id_story' } );
                        console.log('share_click / dialog / id_story')

                        if ( _data ) {
                            _btnObj.addClass( 'is-show' )
                        } else {
                            _btnObj.addClass( 'is-loading' );
                            _ajaxRequest();
                        }

                    }

                } );

                _btnShareLink.on( 'click', function() {
                    _btnObj.removeClass( 'is-show' );

                    gtag( 'event', 'copyurl_click', { 'event_category': 'dialog', 'event_label': 'id_story' } );
                    console.log('copyurl_click / dialog / id_story')

                } );

                _btnShareFacebook.on( 'click', function() {
                    gtag( 'event', 'facebook_click', { 'event_category': 'dialog', 'event_label': 'id_story' } );
                    console.log('facebook_click / dialog / id_story')
                } );

                _btnShareTelegram.on( 'click', function() {
                    gtag( 'event', 'telegram_click', { 'event_category': 'dialog', 'event_label': 'id_story' } );
                    console.log('telegram_click / dialog / id_story')
                } );

            },
            _ajaxRequest = function () {

                $.ajax({
                    type: 'POST',
                    url: '/generate',
                    data: {
                        'text': _btnObj.attr('data-text')
                    },
                    success: function( data ){
                        _data = JSON.parse( data );
                        _setButtons( _data );
                    },
                    error: function (XMLHttpRequest) {
                        _btnObj.removeClass( 'is-loading' );
                        if (XMLHttpRequest.statusText != 'abort') {
                            alert(XMLHttpRequest.statusText);
                        }
                    }
                });

            },
            _setButtons = function ( data ) {

                var url = data.url,
                    clipboard = new ClipboardJS( _btnShareLink[0] );

                _btnObj.addClass( 'is-show' ).removeClass( 'is-loading' );

                _btnShareLink.attr( 'data-clipboard-text', url );
                _btnShareFacebook.attr( 'href', 'https://www.facebook.com/sharer.php?s=100&p[title]='+ _sharingData.title +'&u='+ url +'&t='+ _sharingData.title +'&p[summary]='+ _sharingData.text +'&p[url]='+ url +'' );
                _btnShareTelegram.attr( 'href', 'https://telegram.me/share/url?url='+ url +'&text='+ _sharingData.text );

            },
            _addButton = function () {

                if ( obj ){
                    obj.append( _btnObj );
                } else {
                    _contentWrap.append( _btnObj );

                    _wrap.animate({ scrollTop: _contentWrap.outerHeight() }, 300 );

                }

            },
            _constructor = function () {
                _addButton();
                _onEvents();
            };

        _constructor();

    };

    var ShowAboutUs = function () {

        var menu = $( '.menu' ),
            aboutSwitch = $('#js-menu-about'),
            backSwitch = $('#js-menu-back');

        aboutSwitch.on( 'click', function () {
            aboutSwitch.addClass('is-hide');
            backSwitch.removeClass('is-hide');

            $('#about-us').addClass( 'is-active' ).scrollTop(0);
            $('.content').css('pointer-events','none');

            $('.site').addClass('is-dark-theme');

            $('#for-content').hide();
            $('#for-about-us').show();

            $('.site__header').addClass( 'is-dark' ).removeClass( 'is-light' );

            gtag( 'event', 'about_click', { 'event_category': 'common' } );
            console.log('about_click / common')

        } );

        backSwitch.on( 'click', function () {

            if ( $('#js-menu-about').length > 0 ){
                aboutSwitch.removeClass('is-hide');
            } else {
                $( '.hamburger' ).removeClass('is-hide');
            }

            backSwitch.addClass('is-hide');

            $('#about-us').removeClass( 'is-active' );
            $('.content').css('pointer-events','all');

            $('#for-content').show();
            $('#for-about-us').hide();

            $('.site').removeClass('is-dark-theme');

            $('.site__header').removeClass( 'is-light is-dark' );

            if ( !$('.content').hasClass( 'is-hide' ) ) {
                $('.site__header').addClass( 'is-dark' );
            }

            gtag( 'event', 'back_click', { 'event_category': 'common' } );
            console.log('back_click / common')

        } );

    };

    var WaitingChecker = function () {

        var _startTime = -1;
        var _carriage = $('<i id="waiting"/>');
        var _arr = [ 'зараз кінчу', 'підкручую ŌZ!()#чик', 'калібрую вриви' ];

        var _step = function (time) {

                if ( _waitingCheckerTimer == null )
                    return;

                var progress;

                if (_startTime < 0)
                    _startTime = time;

                progress = (time - _startTime) / 300;

                if (progress > 1) {
                    progress = 1;
                    _startTime = time;

                    _addNewWord();

                } else {
                    window.requestAnimationFrame(_step);
                }

            },
            _addNewWord = function() {

                _carriage.text( _arr[_curNumWaitingWord] );

                _curNumWaitingWord++;

                if ( _curNumWaitingWord >= _arr.length )
                    _curNumWaitingWord = 0;

                window.requestAnimationFrame(_step);

            };

        _waitingCheckerTimer = setTimeout( function () {

            var paragraph =  $('.content__wrap').find( '.is-thinking' );

            paragraph.append( _carriage );
            paragraph.removeClass( 'is-thinking' );

            window.requestAnimationFrame(_step);

        }, 3000 );

    };

    var WaitingCheckerClear = function () {

        clearTimeout(_waitingCheckerTimer);
        _waitingCheckerTimer = null;

        $('.content__wrap').find('#waiting').remove();

    };

    function Disclaimer (obj) {

        var _clientY = 0,
            _scrollContainer = obj,
            _message = _scrollContainer.find('.disclaimer');


        var _onEvents = function () {

                _scrollContainer.on( 'touchmove', _showMobileDisclaimer );

            },
            _showMobileDisclaimer = function () {

                $( window ).on( {
                    'touchmove': function ( e ) {

                        var deltaY;

                        deltaY = e.changedTouches[0].clientY - _clientY;

                        if ( deltaY > $(window).outerHeight() / 3 ){

                            _message.hide();
                            _scrollContainer.find('.site__content').css('height', $(window).outerHeight() +'px');

                        } else {

                            _message.show();

                            _scrollContainer.find('.site__content').css('height', $(window).outerHeight() + _message.outerHeight() +'px');
                            _scrollContainer.off( 'touchmove', _showMobileDisclaimer );

                        }

                    },
                    'touchstart': function ( e ) {
                        _clientY = e.touches[0].clientY;
                    }
                } );

            };

        _onEvents();

    }

    function ElementsSizeOnDesktop(){

        //private properties
        var _html = $( 'html' ),
            _buttons = $( 'button' ),
            _window = $( window );

        //private methods
        var _construct = function(){
                _onEvent();
                _proportionalElements();
            },
            _onEvent = function () {

                _window.on( 'resize', function () {
                    _proportionalElements();
                } );

            },
            _proportionalElements = function () {
                if ( _window.outerWidth() >= 1920 ) {

                    var koef = _window.outerWidth() * 100 / 1200;

                    _html.css( 'font-size',  koef + 'px' );
                    _buttons.css( 'font-size', koef + 'px' );

                } else {
                    _html.removeAttr( 'style' );
                    _buttons.removeAttr( 'style' );
                }
            };

        //public properties

        //public methods

        _construct();

    };

    function Preloader() {
        let frameIndex = 0;
        let tickCount = 0;
        let ticksPerFrame = 0;
        let numberOfFrames = 13;
        let startTime = 0;
        let imagesLoaded = false;
        let audiosLoaded = false;
        const audiosArr = [
            'audio/bg.mp3',
            'audio/dick.mp3',
            'audio/loading.mp3',
            'audio/loner.mp3',
            'audio/no_18.mp3',
            'audio/party.mp3',
            'audio/vagina.mp3',
            'audio/yes_18.mp3'];
        let loadedAudioCount = 0;
        const starFolder = 'star';
        const mainCanvas = document.getElementById('stars-screen-canvas');
        const mainContext = mainCanvas.getContext('2d');

        mainCanvas.width = isDesktop ? 1060 : 460;
        mainCanvas.height = isDesktop ? 1060 : 570;

        const canvasWidth = mainCanvas.width;
        const canvasHeight = mainCanvas.height;

        audiosArr.forEach((name) => {
            const item = new Audio(name);

            item.load();
            audios[name.replace('audio/', '').replace('.mp3', '')] = item;

            item.addEventListener( 'canplay', () => {
                loadedAudioCount++;

                if (loadedAudioCount === audiosArr.length) {
                    audiosLoaded = true;

                    if (imagesLoaded && audiosLoaded) {
                        allLoaded();
                    }
                }
            } );
        });

        for (let i = 0; i <= (numberOfFrames - 1); i++) {

            const img = new Image();

            img.onload = function () {
                frameIndex++;

                setTimeout(() => {
                    if (frameIndex == numberOfFrames) {
                        frameIndex = 0;
                        imagesLoaded = true;

                        if (imagesLoaded && audiosLoaded) {
                            allLoaded();
                        }
                    }
                }, 5);

            };

            img.src = `img/${starFolder}/img_${i}.svg`;

            images.push(img);

        }

        function allLoaded() {
            $('.preloader').remove();
            start();
        }

        function update() {
            tickCount++;

            if (tickCount > ticksPerFrame) {
                tickCount = 0;
                if (frameIndex < numberOfFrames - 1) {
                    frameIndex++;
                } else {
                    // frameIndex = 0;
                    frameIndex++;
                    // TODO Star animation completed
                    $('.stars-screen').removeClass('is-playing');
                    SetPageContent();
                }
            }
        }

        function render() {
            if (frameIndex < 13) {
                mainContext.clearRect(0, 0, canvasWidth, canvasHeight);
                mainContext.drawImage(images[frameIndex], 0, 0, canvasWidth, canvasHeight);
                mainContext.getImageData(0, 0, canvasWidth, canvasHeight);
            }
        }

        function start() {
            let loop = (e) => {
                const delta = e - startTime;

                if (delta > 100 && frameIndex < 13) {
                    startTime = e;
                    update();
                    render();
                }

                if (frameIndex < 13) {
                    window.requestAnimationFrame(loop);
                }
            };

            window.requestAnimationFrame(loop);
        }

    }

    function Menu() {

        //private properties
        var _obj = $('.navigation'),
            _links = _obj.find('li'),
            _mobileBtn = $( '.hamburger' ),
            _siteHeader = $('.site__header'),
            curScreen = $('#add-video'),
            videoCover = curScreen.find('.video__cover')[0],
            _canvas = curScreen[0].querySelector('.video__cover-canvas'),
            _ctx = _canvas.getContext('2d'),
            playAudioBtn = $('.play-audio'),
            playBtn = curScreen.find('.video__play-btn');

        //private methods
        var _onEvent = function() {

                _mobileBtn.on( 'click', function () {

                    if ( _mobileBtn.hasClass( 'is-active' ) ){
                        _hideMobileMenu();
                    } else {
                        _showMobileMenu();
                    }

                } );

                _links.on( 'click', function () {
                    _hideMobileMenu();

                    if (_playerAdd !== null){
                        _playerAdd.destroy();
                        _playerAdd = null;
                    }

                    switch ( $(this).data('rote') ) {
                        case 'about-us':

                            $('#add-video').removeClass( 'is-active' );
                            curScreen.find('.video').removeClass('is-playing');

                            $('.content').css('pointer-events','all');
                            $('.site__header').removeClass( 'is-dark' );

                            if ( !$('.content').hasClass( 'is-hide' ) ) {
                                $('.site__header').addClass( 'is-dark' );
                            }

                            _showAboutUs();
                            break;
                        case 'first-contact':

                            $('#add-video').removeClass( 'is-active' );
                            curScreen.find('.video').removeClass('is-playing');

                            $('.content').css('pointer-events','all');
                            $('.site__header').removeClass( 'is-dark' );
                            $('.site').removeClass('is-dark-theme');

                            if ( !$('.content').hasClass( 'is-hide' ) ) {
                                $('.site__header').addClass( 'is-dark' );
                            }

                            break;
                        case 'video-1':

                            _showVideo();

                            break;
                        case 'video-2':

                            _showVideo();

                            break;
                    }

                } )

                playAudioBtn.on( 'click', function () {

                    if ( _playerAdd && _playerAdd.getPlayerState() == 1 && _bgSoundIsPlaying ){
                        _backgroundSound.pause();
                    }

                } )

                playBtn.on( 'click', function (e) {
                    e.preventDefault();

                    setTimeout( function () {

                        // videoCover.remove();
                        // _canvas.remove();

                        _playerAdd.playVideo();

                        var _playersWrap = curScreen.find('iframe')[0];

                        if ( $(window).outerWidth() < 767 ){

                            var requestFullScreen = _playersWrap.requestFullScreen || _playersWrap.mozRequestFullScreen || _playersWrap.webkitRequestFullScreen;
                            if (requestFullScreen) {
                                requestFullScreen.bind(_playersWrap)();
                            }

                        }

                        curScreen.find('.video').addClass('is-playing');

                    }, 500 )

                } );

                $(window).on('resize', function () {
                    _canvas.width = $(window).outerWidth();
                    _canvas.height = $(window).outerHeight();
                })

            },
            _showVideo = function () {

                gtag('config','UA-157469096-1', {'page_location': '/video'});

                curScreen.find('.video').removeClass('is-playing');

                _playerAdd = new YT.Player( 'playerAdd', {
                    height: '360',
                    width: '640',
                    videoId: VIDEO_ID,
                    host: 'https://www.youtube.com',
                    origin: _globalURL
                });

                _playerAdd.addEventListener( 'onStateChange', function (state) {
                    switch (state.data) {
                        case 1: // playing

                            if ( _bgSoundIsPlaying ){
                                _backgroundSound.pause();
                            }

                            break;
                        case 2: // pause

                            if ( _bgSoundIsPlaying ){
                                _backgroundSound.play();
                            }
                            break;
                    }
                } );

                $('.content').css('pointer-events','none');
                $('.site').addClass('is-dark-theme');
                $('.site__header').addClass( 'is-dark' ).removeClass( 'is-light' );

                if ( $(window).outerWidth() < 992 ){
                    videoCover.play();
                    window.requestAnimationFrame(loop);

                    _canvas.width = $(window).outerWidth();
                    _canvas.height = $(window).outerHeight();
                    _ctx.clearRect(0, 0, _canvas.width, _canvas.height);

                } else {
                    videoCover.play();
                }

                curScreen.addClass( 'is-active' );

                function loop(){

                    var canvasH = $(window).outerWidth() / 375 * $(window).outerHeight();
                    var canvasW = $(window).outerWidth();
                    var canvasSY = ( canvasH - $(window).outerHeight() ) / 2 * -1;
                    var canvasSX = 0;

                    if ( canvasH < $(window).outerHeight() ){
                        canvasH = $(window).outerHeight();
                        canvasSY = 0;
                        canvasW = $(window).outerHeight() / 666 * $(window).outerWidth();
                        canvasSX = ( canvasW - $(window).outerWidth() ) / 2 * -1;
                    }

                    _ctx.drawImage(videoCover, canvasSX, canvasSY, canvasW, canvasH);

                    if (!curScreen.hasClass('is-active'))
                        return;

                    window.requestAnimationFrame(loop);
                }

            },
            _showAboutUs = function () {

                _mobileBtn.addClass('is-hide');
                $('#js-menu-back').removeClass('is-hide');

                $('#about-us').addClass( 'is-active' ).scrollTop(0);
                $('.content').css('pointer-events','none');

                $('#for-content').hide();
                $('#for-about-us').show();

                $('.site__header').addClass( 'is-dark' ).removeClass( 'is-light' );

                gtag( 'event', 'about_click', { 'event_category': 'common' } );
                console.log('about_click / common')

            },
            _hideMobileMenu = function () {

                _mobileBtn.removeClass( 'is-active' );
                _obj.removeClass( 'is-show' );

                _siteHeader.removeClass('is-menu-open');

            },
            _showMobileMenu = function () {

                _mobileBtn.addClass( 'is-active' );
                _obj.addClass( 'is-show' );

                _siteHeader.addClass('is-menu-open');

            },
            _construct = function() {
                _onEvent();
            };

        //public properties

        //public methods

        _construct();

    }

})();

function onYouTubeIframeAPIReady() {
    _player = new YT.Player( 'player', {
        height: '360',
        width: '640',
        videoId: VIDEO_ID,
        host: 'https://www.youtube.com',
        origin: _globalURL
    });

}

window.requestAnimFrame = (function () {
    return window.requestAnimationFrame ||
        window.webkitRequestAnimationFrame ||
        window.mozRequestAnimationFrame ||
        window.oRequestAnimationFrame ||
        window.msRequestAnimationFrame ||
        function (callback) {
            window.setTimeout(callback, 1000 / 60);
        };
})();
